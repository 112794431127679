@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}
.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.text-muted a {
  color: #fff;
}
a {
  text-decoration: none;
  background-color: transparent;
}
a {
  color: #4980be;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: none;
  background-color: transparent;
}
p {
  font-size: 14px;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.mr-2,
.mx-2 {
  font-size: 20px;
  margin-right: 1rem !important;
}
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #4980be;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
}
.pt-2 .nav-item {
  color: #fff;
  padding-right: 40%;
  text-align: justify;
}

.sidebar .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  margin-left: 10px;
  margin-right: 17px;
  overflow: visible;
  vertical-align: -0.125em;
}
.nav-link {
  font-weight: 300;
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.sidebar.is-open {
  margin-left: 0;
  /* transition: .5s; */
}

.sidebar-header {
  width: 250px;
  height: 70px;
  background: #e6e8ea;
  color: #adb5bd;
}
@media only screen and (min-width: 600px) {
  .sidebar-header .svg-inline--fa {
    display: none;
  }
}
.sidebar-header h3 {
  color: #4980be;
  padding: 25px;
  font-size: 20px;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}
li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.pt-2 svg {
  font-size: 16px;
  margin-left: 10px;
}
.siderbarSpan {
  padding-top: 35px;
  font-size: 14px;
}
.pro-icon-wrapper {
  background-color: rgba(248, 249, 250, 0.27058823529411763);
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 2px;
  font-size: 14px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
}
@media only screen and (max-width: 700px) {
  .sidebar-header {
    width: 100%;
    margin-left: 0px;
    height: 70px;
    background: #e6e8ea;
    color: #adb5bd;
  }
  .sidebar-header .svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    font-size: 25px;
    margin-left: 10px;
    color: #ccc;
    margin-right: 17px;
    overflow: visible;
    vertical-align: -0.125em;
  }
  .sidebar-header .svg-inline--fa:hover {
    display: var(--fa-display, inline-block);
    height: 1em;
    margin-left: 10px;
    font-size: 25px;
    color: #ccc;
    margin-right: 17px;
    overflow: visible;
    vertical-align: -0.125em;
  }
  .sidebar-header h3 {
    margin-left: -200px;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 45px;
    margin-top: 10px;
    border-color: rgba(0, 0, 0, 0.1);
  }
  .pt-2,
  .py-2 {
    width: 100%;
    padding-top: 0.5rem !important;
  }
  .card {
    margin-bottom: 25px;
  }
  .collapse.show,
  .collapsing {
    background: #fff;
  }
  .dropdown-menu {
    position: absolute;
    top: 112%;
    left: 14px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
}

/* ---------------------------------------------------
    SIDEBAR STYLE ENDS
----------------------------------------------------- */

/* ---------------------------------------------------
    NAVBAR STYLE 
----------------------------------------------------- */
.p-3 {
  padding: 0.3rem !important;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-item {
  color: #ced4da;
  font-weight: 300;
  padding: 10px;
}
.nav-item a {
  font-weight: 300;
  display: block;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}
.nav .nav-item.active,
.nav .nav-item.show,
.navbar-nav .show > .nav-item {
  background-color: #fff3;
  text-align: justify;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #333;
  background-color: red;
  font-weight: 600;
  /* border: 1px #dee2e6a3 solid; */
}
.nav-item:hover {
  color: #fff;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}
.dropdown-menu {
  position: absolute;
  top: 112%;
  left: -80px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.navbar {
  margin-left: -40px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
@media only screen and (max-width: 600px) {
  .dropdown-menu {
    position: absolute;
    top: 112%;
    left: -100px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  .nav {
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}
/* ---------------------------------------------------
    NAVBAR STYLE ENDS
----------------------------------------------------- */
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 0px;
  margin-left: 20px;
  min-height: 100vh;
}

@media only screen and (max-width: 575.98px) {
  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    /* transition: all 0.5s, height 0s; */
    height: 100vh !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
/* ---------------------------------------------------
    CONTENT STYLE ENDS
----------------------------------------------------- */
/* ---------------------------------------------------
    Account STYLE 
----------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  /* font-weight: 600; */
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}
.text-muted a {
  color: #fff;
}
a {
  text-decoration: none;
  background-color: transparent;
}
a {
  color: #4980be;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: none;
  background-color: transparent;
}
p {
  font-size: 14px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  color: #6b6a6a;
  font-weight: 400;
  font-size: 14px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.svg-inline--fa:hover {
  color: #6b6a6a;
}
.navbar-brand {
  display: inline-block;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  /* padding-left: 30%; */
  margin-bottom: 0;
  list-style: none;
}

.homepage {
  position: relative;
  background-size: cover;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #134173b3;
}
.RegisterSec {
  background-color: #fff;
  height: 1200px;
}
.loginSec {
  margin-top: 2%;
  padding: 5%;
}
.registerSec {
  margin-top: 10%;
  padding: 10%;
}

.loginSubmit {
  width: 100%;
  padding: 5px 5px 5px 5px;
}
.loginSubmit:hover {
  width: 100%;
  padding: 5px 5px 5px 5px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.marker-btn img {
  width: 20px;
  height: 20px;
}
.marker-btn {
  border: none;
  background: none;
}

.danger-alert {
  color: #721c24;
}
.Toastify__toast--default {
  color: #fafafa;
}
.mr-auto,
.mx-auto {
  float: right;
}
.bg-dark {
  background-color: #fff !important;
  color: #333;
  border-bottom: 1px #ced4da6e solid;
}
.navbar-dark .navbar-nav .nav-link {
  color: #6b6a6a;
  font-weight: 500;
}
.navbar-dark .navbar-nav .nav-link :hover {
  color: #333;
}
.logo {
  width: 40px;
}
.navbar-brand h3 {
  color: #394ca5;
  font-size: 18px;
}
.elite {
  font-weight: 400;
}

.navBorder {
  padding-top: 10px;
  border-bottom: 2px #6c757d24 solid;
}
.loginHead {
  margin-top: 30px;
  text-align: center;
}
.loginHead h2 {
  font-size: 50px;
  font-weight: bolder;
  padding-top: 10px;
}
.registerHead {
  margin-top: 30px;
  text-align: center;
}
.registerHead small {
  color: #333;
}
.registerHead h2 {
  font-size: 50px;
  font-weight: bolder;
  padding-top: 10px;
}
.resetHead {
  margin-top: 150px;
  text-align: center;
}
.resetHead h2 {
  font-size: 50px;
  font-weight: bolder;
}
.loginSec {
  background-color: #fff;
  border: 1px #00000014 solid;
  border-top: 2px solid;
  border-top-color: #4980be;
  box-shadow: 5px 10px #8888880d;
}
.registerSec {
  background-color: #fff;
  border: 1px #00000014 solid;
  border-top: 2px solid;
  border-top-color: #4980be;
  box-shadow: 5px 10px #8888880d;
}
.resetSec {
  background-color: #fff;
  border: 1px #00000014 solid;
  border-top: 2px solid;
  border-top-color: #4980be;
  box-shadow: 5px 10px #8888880d;
}
.resetSec {
  margin-top: 2%;
  padding: 5%;
}
@media only screen and (min-width: 600px) {
  .resetSec {
    background-color: #fff;
    border: 1px #00000014 solid;
    border-top: 2px solid;
    border-top-color: #4980be;
    box-shadow: 5px 10px #8888880d;
  }
  .resetSec {
    margin-top: 2%;
    padding: 10%;
  }
}

small {
  font-size: 15px;
  font-weight: 700;
  color: #4980be;
}
.loginHead small {
  font-size: 15px;
  font-weight: 700;
  color: #333;
}
label {
  margin-bottom: 0.5rem;
  float: left;
  font-weight: 500;
}
.form-check-input {
  float: left;
  position: relative;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-label {
  float: left;
  font-weight: 500;
  font-size: 10px;
  margin-top: 4px;
}
.fogtPsswrd {
  float: right;
  font-size: 13px;
  font-weight: 600;
}
.list-group-item {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 0.75rem 1.25rem;
  text-align: justify;
  background-color: #cccccc00;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.footerSec {
  margin-top: 100px;
}
.Fterbld {
  font-weight: 600;
  font-size: 16px;
}
.bg-light {
  background-color: #fff !important;
}
.cpyrght {
  font-size: 12px;
}
.wlcmnt {
  margin-top: 50%;
  margin-bottom: 5%;
  font-size: 16px;
  color: #6b6a6a;
  font-weight: 700;
  text-align: justify;
}
.landhd {
  color: #4980be;
  font-size: 50px;
  font-weight: 800;
  line-height: 54px;
  text-align: justify;
}
.landNte {
  margin-top: 5%;
  text-align: justify;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #6b6a6a;
}
.navSgn {
  margin-top: 12px;
}
.landBtn {
  float: left;
  margin-top: 15px;
  margin-bottom: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}
.btn-primary:hover {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}
.btn-outline-primary {
  color: #4980be;
  border-color: #4980be;
}
.btn-outline-primary:hover {
  color: #fff;
  border-color: #4980be;
}
.btn-light {
  color: #4980be;
  background-color: #33333317;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}

.svg-inline--fa:hover {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.dropsec {
  width: 1200px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 1.25rem 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;

  border: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.fontBtn {
  border-radius: 60px;
  padding-left: 10px;
  color: #fff;
  width: 80px;
  height: 80px;
}
.fontBtn:hover {
  border-radius: 60px;
  padding-left: 10px;
  color: #fff;
  width: 80px;
  height: 80px;
}
.fontBtn .svg-inline--fa {
  display: inline-block;
  font-size: 48px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin: 10px 0px 32px 0px;
}
.fontBtn .svg-inline--fa:hover {
  display: inline-block;
  font-size: 48px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  margin: 10px 0px 32px 0px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #333;
  text-decoration: none;
  background-color: #fff;
}
.fontfeatrs {
  background-color: #cccccc14;
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
}
.fontContnr {
  padding-top: 80px;
}
.fontfeatrs h2 {
  color: #49505780;
  font-weight: 700;
  font-size: 50px;
  padding-top: 50px;
}
.fontfeatrsSec p {
  color: #6b6a6a;
  padding-top: 5px;
  line-height: 25px;
  font-weight: 500;
}
.fontfeatrsSec h6 {
  color: #495057e0;
  padding-top: 15px;
}
.fontTestmonal {
  background-color: #e1e4e6;
  margin-top: 20px;
}
.fontawesmeCntrFld {
  padding-right: 0px;
  padding-left: 0px;
}
.testimonialRw {
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
  background-color: #cccccc14;
}

label .svg-inline--fa:hover {
  display: inline-block;
  font-size: 45px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 15px;
}
label .svg-inline--fa:hover {
  display: inline-block;
  font-size: 45px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 15px;
}

@media only screen and (max-width: 600px) {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 7px;
    margin-right: 60px;
  }
}
@media only screen and (min-width: 600px) {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 7px;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 7px;
  }
}

.successCard .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 7px;
  margin-right: 0px;
}
.card img {
  padding-top: 20px;
}
.testimonialRw h2 {
  font-size: 60px;
  padding-top: 40px;
  font-weight: 1000;
  line-height: 10px;
}
.testimonialRw h6 {
  color: #4980be;
  font-size: 18px;
  padding-top: 100px;
  font-weight: 700;
  line-height: 10px;
}
.testimonialRw p {
  font-size: 16px;
  padding-top: 12px;
  font-weight: 400;
  text-align: justify;
}
.testimonialRw .btn-primary {
  margin-top: 30px;
}
.PricingSec {
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 40px 0px;
}
.PricingSec h2 {
  font-size: 40px;
  padding-top: 100px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
}
h5 {
  text-align: center;
}
.PricingSec p {
  font-size: 16px;
  padding-top: 12px;
  font-weight: 400;
  text-align: center;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #4980be;
  color: #fff;
  border-top: 1px solid #4980be;
}
.card-footer.text-muted {
  background-color: #4980be;
  color: #fff !important;
  cursor: pointer;
  font-weight: 500;
}
.card-footer.text-mutedCurrent {
  background-color: green;
  color: #fff !important;
  pointer-events: none;
  cursor: no-drop;
  font-weight: 500;
}
.card-footer.text-mutedBeta {
  background-color: #4980be;
  color: #fff !important;
  pointer-events: none;
  cursor: no-drop;
  font-weight: 500;
}
.card .list-group-item {
  position: relative;
  display: block;
  text-align: justify;
  font-size: 16px;
  padding: 8px 0px;
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.list-group-item .svg-inline--fa {
  margin: 0px;
}
.PricingSec .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-top: 90px;
}
.pricing .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.pricing {
  padding-left: 0px;
  padding-right: 0px;
  background-color: #ffffff;
}
.pricingPrt {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 100px;
  background-color: #ffffff;
}
.pricing button {
  float: right;
}
.card-header h3 {
  float: left;
  font-size: 60px;
  font-weight: bolder;
  padding-left: 20px;
}
.card-header h4 {
  float: right;
  font-size: 60px;
  margin-top: -30px;
  font-weight: bolder;
}
.card-header p {
  font-size: 30px;
  font-weight: 600;
  color: #6c757d;
  padding-left: 0px;
  padding-top: 40px;
  width: 80%;
}
.text-muted {
  font-weight: 700;
}
.socialBar .svg-inline--fa {
  display: inline-block;
  font-size: 25px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #333;
  margin: 15px;
}
.socialBar .list-group-item {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 0.75rem 0px;
  text-align: justify;
  background-color: #cccccc00;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0px;
  border-left-width: 0;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #333;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link:hover {
  color: #333;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #333;
}
.pricingReg {
  background-color: #fff;
}
.PricingReg .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-top: 20px;
}
.PricingPlanSec {
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
}
.PricingPlanSec h2 {
  font-size: 50px;
  padding-top: 100px;
  font-weight: bolder;
  text-align: center;
}
.PricingPlanSec p {
  font-size: 16px;
  padding-top: 12px;
  font-weight: 400;
  text-align: center;
}
.PricingPlanSec .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-top: 20px;
}
.PricingPlanSec h4 {
  float: right;
}
.scrollTop {
  position: fixed;
  left: 90%;
  width: 50px;
  bottom: 80px;
  height: 50px;
  color: #fff;
  border-radius: 50px;
}
@keyframes scrollTop {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(-1px);
  }
}
.scrollbtn {
  position: fixed;
  left: 90%;
  width: 50px;
  bottom: 80px;
  height: 50px;
  color: #fff;
  border-radius: 50px;
  border: 0px #fff solid;
  background-color: #8aa8cc;
}
.scrollbtn:hover {
  position: fixed;
  left: 90%;
  width: 50px;
  bottom: 80px;
  height: 50px;
  color: #fff;
  border-radius: 50px;
  border: 0px #fff solid;
  background-color: #4980be;
}
.scrollbtn .svg-inline--fa {
  color: #fff;
  font-size: 30px;
  margin: 0px 0px 0px 0px;
}
.card-header p {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #6c757d;
  padding-left: 0px;
  padding-top: 26px;
  width: 80%;
}
.appPriciing .card-header p {
  float: left;
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  padding-left: 0px;
  padding-top: 40px;
  width: 80%;
}
.appPriciing .card-header h3 {
  float: left;
  font-size: 40px;
  font-weight: bolder;
  padding-left: 20px;
}

.dropdown-item p {
  padding-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}
.headerImg {
  top: 0px;
  background-image: url(./Img/headerbg.png);
  position: relative;
  background-size: cover;
  width: 200px;
}
.layer {
  background-color: rgb(65 107 162 / 84%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.layer img {
  padding: 140px 0px 0px 25px;
  width: 75%;
}
.layerPric {
  background-color: rgb(65 107 162 / 84%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.layerPric img {
  padding: 60px 0px 0px 30px;
  width: 75%;
}
.figure-img {
  margin-bottom: 0.5rem;
  margin-top: 5%;
  margin-left: 18%;
  line-height: 1;
}
.colr {
  color: #4980be;
}
.featrBtn {
  background-color: #fff;
  border-color: #3333330f;
}
.featrBtn {
  border-radius: 60px;
  padding-left: 10px;
  color: #fff;
  width: 100px;
  height: 100px;
}
.featrBtn:hover {
  border-radius: 60px;
  padding-left: 10px;
  color: #fff;
  width: 100px;
  height: 100px;
}
.featrBtn .svg-inline--fa {
  display: inline-block;
  font-size: 60px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin: 15px 0px 32px 0px;
}
.featrBtn .svg-inline--fa:hover {
  display: inline-block;
  font-size: 60px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  margin: 15px 0px 32px 0px;
}
.testimg {
  border-radius: 60px;
}
.fontfeatrs h2 {
  color: #49505780;
  font-weight: 700;
  font-size: 50px;
  padding-top: 50px;
}
/*--slider----*/
.sliderSec {
  padding-top: 100px;
  margin-bottom: 100px;
}
.sliderDashSec {
  padding-top: 100px;
  margin-bottom: 100px;
  background-color: #cccccc14;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #333;
  text-align: justify;
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #333;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 60px;
  height: 20px;
  background-color: #4980be;
  border-radius: 10px;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 75%;
  left: 15%;
  top: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #333;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 5px;
  height: 5px;
  margin-right: 3px;
  margin-left: 3px;

  text-indent: -999px;
  cursor: pointer;
  background-color: #4980be;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 65%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 5%;
  background-color: #4980be;
  color: #333;
  border-radius: 10%;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  position: absolute;
  top: 65%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 5%;
  background-color: #4980be;
  color: #333;
  border-radius: 10%;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next {
  right: 170px;
}
.carousel-control-prev {
  left: 165px;
}
.sliderDashSec .carousel-control-prev {
  left: 188px;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 110px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.appCarousel h1 {
  color: #4980be;
  font-weight: 700;
}
.carousel-caption h3 {
  font-size: 30px;
  padding-top: 30px;
  font-weight: 700;
}
.carousel-caption p {
  font-size: 14px;
  line-height: 25px;
  padding-top: 30px;
  font-weight: 500;
  text-align: left;
  color: #6b6a6a;
}
.dashboardPricng {
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
}
.appPricng {
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #333;
  background-color: #fff;
  border-color: #ffff;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #fff;
}
.acrdInfo {
  margin-top: -16px;
}
.cardDetail {
  padding: 100px;
}
.cardPaySec {
  margin-top: 150px;
  padding: 5px;

  box-shadow: 5px 5px #8888881c;
}
.pricingDetail {
  background-color: #4980be;
}
.subscrbBtn {
  width: 100%;
  font-weight: 600;
  background-color: #4980be;
  color: #fff;
  padding: 5px;
  border: 2px #4980be solid;
}
label {
  margin-bottom: 0.5rem;
  float: left;
  font-weight: 500;
  font-size: 13px;
}
.dividertext h6 {
  background-color: #fff;
  /* margin-top: -5px; */
  width: 25%;
  margin-top: -26px;
  margin-left: 34%;
  font-size: 14px;
  color: #33333361;
  margin-bottom: 50px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ced4daed;
  border-radius: 0.25rem;
}
.ConfirmTerms {
  padding-top: 30px;
  color: #96999c;
  font-size: 12px;
}
.subscrplan h4 {
  color: #ffffff99;
  font-size: 22px;
  text-align: center;
  margin-top: 20%;
  line-height: 36px;
}
.prcamnt h3 {
  color: #ffffff99;
  font-size: 50px;
  margin-top: 5%;
}
.prcamnet h3 {
  color: #ffffff99;
  font-size: 60px;
  margin-top: 50px;
  font-weight: 800;
}
.headerImg .svg-inline--fa {
  color: #fff;
  font-size: 50px;
  margin-left: 0%;
  margin-top: 60px;
}
.text-amnt {
  color: #ffffff99;
}
.navbar-brand h2 {
  color: #333;
  margin-left: 76%;
  margin-top: -34%;
  color: #4980be;
  font-size: 35px;
  font-weight: 700;
  line-height: 54px;
  text-align: justify;
}
.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}
.ElementsApp .InputElement.is-invalid {
  color: #333;
}
.InputElement is-invalid Input {
  color: #333;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.StripeElement {
  border: 1px #ccc solid;
}
.cardlbl {
  margin-top: -10px;
}
.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
.has-error {
  color: #a94442;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}

.password__show .svg-inline--fa:hover {
  color: #4980be;
  /* margin: -28px 0px 28px 315px; */
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__show .svg-inline--fa {
  color: #4980be;
  /* margin: -28px 0px 28px 315px; */
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.loginSec .input-group-text {
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ced4daed;
  border-radius: 0.25rem;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  color: #333;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.nav-item:hover .dropdown-menu {
  display: block !important;
}

html {
  scroll-behavior: smooth;
}
@media (max-width: 767px) {
  .fixed-top {
    position: absolute !important;
    top: 0px;
  }
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 13px;
  left: 70px;
  z-index: 1030;
}

.input-group-append .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  /*user-select: none; */
  /*background-color: transparent; */
  border: 1px solid transparent;
  padding: 1px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.loginSec .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 3px;
}
.loginSec .svg-inline--fa:hover {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 5px;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
a {
  color: #4980be;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}
.Toastify__toast-container {
  z-index: 9999;
  font-weight: 200;
  position: fixed;
  padding: 4px;
  width: auto;
  box-sizing: border-box;
  color: #fff;
}
text-danger {
  color: #dc3545 !important;
  font-weight: 500;
  font-size: 12px;
}
/*--sidebar----*/
.pro-sidebar {
  color: #adadad;
  height: 100%;
  /* width: 270px;
  min-width: 270px; */
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
  top: 16px;
}
@media only screen and (max-width: 800px) {
  .pro-sidebar {
    color: #adadad;
    height: 100%;
    /* width: 270px;
    min-width: 270px; */
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
    top: 35px;
  }
}
@media only screen and (max-width: 1200px) {
  .pro-sidebar {
    color: #adadad;
    height: 100%;
    /* width: 270px;
    min-width: 270px; */
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
    top: 50px;
  }
}
.pro-sidebar > .pro-sidebar-inner {
  background: #4980be;
  color: #fff;
  height: 2000px;
  font-weight: 400;
  position: relative;
  z-index: 101;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #cddcec54;
}
.pro-menu-item .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  margin: 15px;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #f8f9fa45;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
  background-color: #cddcec54;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
  color: #d8d8d8;
  background-color: #cddcec54;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: #d8d8d8;
  background-color: #cddcec54;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #fff;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 35px 8px 20px;
  cursor: pointer;
}
.mainNav .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.mainNav .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  margin-bottom: 0;
  list-style: none;
}
.mainNav .bg-light {
  border-bottom: 1px #cccccc4f solid;
}
.mainNav .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .mainNav .navbar {
    padding: 0px 0 0 12px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}
.mainNav .nav-link {
  display: block;
  padding: 0px 30px 0px 0px;
}
.mainNav .svg-inline--fa {
  display: inline-block;
  font-size: 22px;
  height: 1em;
  margin-top: 15px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin-right: 0px;
}
.mainNav .svg-inline--fa.user {
  display: inline-block;
  font-size: 22px;
  height: 1em;
  margin-top: 15px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin-right: 0px;
}
.logodash {
  width: 30px;
  margin-left: 15%;
}
.mainNav a {
  color: #4980be;
  font-weight: 500;
  font-size: 10px;
  text-decoration: none;
  background-color: transparent;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 12px;
}
.block span {
  display: inline-block;
}
.block img {
  padding-top: 15px;
  border-radius: 60px;
}
.mainNav .svg-inline--fa {
  display: inline-block;
  font-size: 14px;
  height: 1em;
  margin-top: 15px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin-right: 0px;
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .dropdown-item span {
  display: inline-block;
  font-weight: 500;
  padding: 2px;
}
.userimg {
  width: 40px;
  border-radius: 40px;
  margin: -35px 0px 0px 10px;
}
.ProfileAccount h2 {
  text-align: justify;
  color: #6b6a6a;

  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
  /* margin-left: 250%; */
}
.profylSec {
  border: 1px #cccccc4f solid;
  padding: 50px;
}
/* .profylImg img{border-radius: 100px;} */
@media only screen and (max-width: 600px) {
  /* .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 2.90rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
  .overlayProfile {
    position: relative;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: red;
  }

  .icon .svg-inline--fa {
    color: #4980be;
    top: 8%;
    display: inline-block;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
}
.card img {
  padding-top: 0px;
  object-fit: cover;
  border-radius: 80px;
}
.profylTitle {
  font-size: 12px;
  text-align: justify;
  font-weight: 600;
}
.profylDetail {
  padding: 20px;
}
.editIcon {
  float: right;
}
.editIcon.svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 5px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #6c757d;
}
svg {
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
}
.editIcon.svg-inline--fa:hover {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 5px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #6c757d;
}

.editImgIcon.svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 100px;
}

.editImgIcon.svg-inline--fa:hover {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 100px;
}

.editlogoIcon.svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 150px;
}
.editlogoIcon.svg-inline--fa:hover {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 150px;
}
.profylImg h2 {
  font-weight: 800;
}
.btnSave {
  float: right;
}
.profylImg label {
  margin-bottom: 0.5rem;
  float: none;
  font-weight: 500;
}
.passdSec .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}
.card-header {
  text-align: left;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.passdSec .card-header {
  text-align: left;
  padding: 8px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.passdSec .card .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ChngePsswd {
  border-radius: 25px;
  float: left;
  font-size: 12px;
}
.twofactr {
  border-radius: 25px;
  float: left;
  font-size: 12px;
}
.passdSec .card-text {
  font-size: 12px;
  line-height: 22px;
}
.password__secureright .input-group {
  position: relative;
  display: flex;
  flex-wrap: unset;
  align-items: stretch;
  width: 100%;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: unset;
  align-items: stretch;
  width: 100%;
}
.input-group-append {
  height: 32px;
  border: 1px solid #dee2e6 !important;
}

.password__secure .svg-inline--fa {
  color: #4980be;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__secure .svg-inline--fa:hover {
  color: #4980be;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__secureright .svg-inline--fa {
  color: #6c757d;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__secureright .svg-inline--fa:hover {
  color: #6c757d;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.trash {
  color: #721c24;
  font-size: 20px;
}
.trash:hover {
  color: #721c24;
  font-size: 20px;
}
.addresssec h3 {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 17px;
  font-weight: 800;
}
.addresssec h5 {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 17px;
}
.seialtext h5 {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 17px;
  margin-top: 10px;
}
.trashdropdown .btn-primary {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.trashdropdown .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.trashdropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(244 245 247 / 50%);
}
.deletenote {
  padding: 20px;
}
.deletenote .btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.deletenote .btn-outline-primary {
  color: #4980be;
  border-color: #4980be;
  font-size: 10px;
}
.deletenote .btn-outline-danger {
  font-size: 10px;
}
.deletenote p {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 20px;
  font-weight: 500;
}
.subsInfo p {
  text-align: justify;
}
.subshead h5 {
  font-size: 14px;
  font-weight: 600;
  text-align: justify;
}
.subshead p {
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.subsInfo h6 {
  cursor: pointer;
  color: #4980be;
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.subsInfo h5 {
  cursor: pointer;
  color: red;
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.secHead h5 {
  font-weight: 600;
  font-size: 14px;
}
.secp h5 {
  font-weight: 400;
  font-size: 12px;
}
.secp .svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: -1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #333;
}
.cloudtext {
  color: #394ca5;
  font-size: 14px;
  text-transform: capitalize;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
  background-color: #cddcec54;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item .active:active {
  color: #d8d8d8;
  background-color: #cddcec54;
  padding: 20px;
}
.pro-menu-item li {
  color: blue;
}
.form-inline .btn-primary {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0px 0px 0px 0px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-signup {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #4980be;
  border: 1px solid #4980be;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-signup:hover {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #4980be;
  border: 1px solid #4980be;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.form-inline .dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.form-inline .dropdown-menu {
  min-width: 10rem;
  left: -120px;
  top: 140%;
}
.form-inline .dropdown-item {
  display: block;
  width: 100%;
  padding: 4px 8px 4px 16px;
  font-size: 10px;
  clear: both;
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}
.spanbell {
  margin: 0px;
  font-size: 12px;
}
.form-inline .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #fff;
}
.form-inline .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.block p {
  font-size: 8px;
  font-weight: bolder;
  margin-top: 2%;
}
.profylDetail .card-text {
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  flex: 1 1;
  min-width: 0;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.icon .svg-inline--fa:hover {
  color: #4980be;
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.overlayProfile {
  position: unset;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  /* opacity: 0; */
  transition: 0.3s ease;
  background-color: red;
}

.profylImg:hover .overlayProfile {
  opacity: 1;
}

.icon {
  /* color: white;
  font-size: 50px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center; */
  color: white;
  font-size: 50px;
  position: absolute;
  top: 21%;
  left: 50%;
  width: 120px;
  height: 120px;
  border: 1px #ccc solid;
  /* background-color: red; */
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 80px;
}
.profylText {
  font-weight: 500;
}

.alert {
  width: 600px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  border-radius: 0px;
  margin-bottom: 0px;
}

.alert-danger {
  color: #333;
  background-color: #fde7e9;
  border-color: #fde7e9;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  padding: 10px 0px 0px 0px;
}
.alert-primary {
  color: #333;
  background-color: #cce5ff;
  border-color: #b8daff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  padding: 0px 0px 0px 0px;
}

.alert-heading {
  color: inherit;
  font-size: 12px;
}
.alert p {
  font-size: 10px;
  font-weight: 500;
  padding-top: 10px;
}
.alert a {
  font-size: 10px;
  font-weight: 500;
}
@media only screen and (max-width: 800px) {
  .alert-primary {
    color: #333;
    display: none;
    background-color: #cce5ff;
    border-color: #b8daff;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    padding: 0px 0px 0px 0px;
  }
}
.close {
  float: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #3286da;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs a {
  color: #333;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  background-color: transparent;
}
.nav-tabs a:hover {
  color: #333;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  background-color: transparent;
}
.nav-tabs .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #6c757d;
  margin: 0px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link .svg-inline--fa.active {
  color: #3286da;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.editcard .card-header {
  text-align: left;
  padding: 30px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.Toastify__toast-body {
  color: #fff;
  margin: auto 0;
  flex: 1 1 auto;
  padding: 15px;
}
.WelcomeNote h3 {
  font-weight: 900;
}
.WelcomeNote p {
  font-weight: 400;
  line-height: 25px;
  color: #17395fed;
}
.input-group input {
  margin: 0;
  font-family: inherit;
  width: 100%;
  font-size: 12px;
  line-height: inherit;
}
.upload .svg-inline--fa {
  color: #4980be;
  display: inline-block;
  font-size: 20px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.upload .svg-inline--fa:hover {
  color: #4980be;
  display: inline-block;
  font-size: 20px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.upload {
  margin-top: 50px;
}
.upload button {
  border-radius: 100px;
  background-color: #e7edf3;
  border: #fff;
}
.card-body h5 {
  text-align: left;
  font-weight: 700;
  font-size: 15px;
}
.card-body p {
  text-align: left;
  font-size: 12px;
}
.switch {
  float: left;
  position: relative;
  overflow: hidden;
}
.saveBtn {
  float: right;
}
.saveBtn:hover {
  float: right;
  color: #fff;
}
.saveBtn .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 0px;
}
.saveBtn:hover .svg-inline--fa:hover {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  margin: 0px;
}
.saveBtn .btn {
  -webkit-appearance: button;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  margin-left: 5px;
  font-size: 12px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-image: initial;
}

.notFoundHead {
  font-weight: 900;
  font-size: 200px;
  color: #ccc;
  margin-top: 50px;
  text-align: center;
}
.notFoundHead .svg-inline--fa {
  color: #4980be;
}
.pageFoundHead h3 {
  font-size: 25px;
  font-weight: 500;
  color: #3333338f;
  line-height: 40px;
  text-align: justify;
}
.btn-home {
  background-color: #5f96d4;
  padding: 20px;
  width: 30%;
  color: #fff;
}
.btn-home a {
  color: #fff;
}
.pro-sidebar.collapsed {
  /* width: 80px; */
  width: 60px;
  min-width: 70px;
}
.text-danger {
  font-size: 12px;
  font-style: italic;
  text-align: justify;
  --bs-text-opacity: 1;
  color: #d51923;
  font-weight: 500;
}
.SubscribeTabs {
  padding-top: 30px;
}
.SubscribeSec .card-header h3 {
  float: left;
  font-size: 14px;
  font-weight: bolder;
  padding-left: 0px;
}
.SubscribeSec small {
  font-size: 10px;
  font-weight: 700;
  color: #4980be;
}
.SubscribeSec .card-header p {
  text-align: justify;
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
  padding-left: 0px;
  padding-top: 0px;
  width: 80%;
}
.SubscribeSec .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  font-size: 10px;
}
.SubscribeIcon .svg-inline--fa {
  color: #474242;
  display: inline-block;
  font-size: 40px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.SubscribeIcon .svg-inline--fa:hover {
  display: inline-block;
  font-size: 40px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.SubscribeSec .card .list-group-item {
  position: relative;
  display: block;
  text-align: justify;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.SubscribeSec .card-footer {
  font-size: 10px;
  padding: 15px;
  color: #fff;
}
.SubscribeSec .card-header {
  text-align: left;
  padding: 15px;
  height: 180px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header h5 {
  margin-top: 20px;
  font-weight: 600;
}
.SubscribeSec .card-body {
  flex: 1 1 auto;
  height: 320px;
  padding: 5px;
  color: #6b6a6a;
  font-weight: 400;
  font-size: 14px;
}
.SubscribeSec .nav-tabs {
  width: 50%;
  border-bottom: 1px solid #dee2e6;
}
.subs .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 97px;
  margin-bottom: 0;
  list-style: none;
}

.noticeModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 50px;
  outline: 0;
}
.modal-title {
  margin-bottom: 0;
  font-size: 23px;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  text-align: justify;
  /* line-height: 24px; */
  font-size: 12px;
  font-weight: 500;
  color: #948a8a;
}
.modal-footer {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  text-align: justify;
  /* line-height: 24px; */
  font-size: 12px;
  font-weight: 500;
  color: #948a8a;
}
.modal-footer p {
  font-size: 11px;
}
.modalPhone {
  font-size: 12px;
  margin-left: 30%;
  float: right;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
@media only screen and (max-width: 800px) {
  .pro-sidebar.collapsed {
    width: 0px;
    min-width: 0px;
  }
}
.main {
  padding: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (max-width: 800px) {
  .main {
    padding: 20px 0px 30px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: absolute;
  }
}
@media only screen and (max-width: 1200px) {
  .main {
    padding: 20px 0px 0px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: absolute;
  }
}
@media only screen and (max-width: 600px) {
  .loginSec {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    display: none;
    border-bottom: 1px solid rgba(173, 173, 173, 0.2);
  }
}
@media only screen and (min-width: 600px) {
  .mobilelogo {
    display: none;
  }
  .logodash {
    margin-top: -10px;
  }
  .mobilelogo .cloudtext {
    margin-top: -10px;
  }
}
@media only screen and (max-width: 600px) {
  .mainNav svg {
    font-size: 24px;
  }
}
.markdown {
  margin-left: 2px;
}
.markdown p {
  text-align: justify;
  font-size: 12px;
}
.markdown h3 {
  text-align: justify;
  font-size: 12px;
}
.markdown h2 {
  text-align: justify;
  font-size: 12px;
  font-weight: bolder;
}
.markdown h5 {
  text-align: justify;
  font-size: 12px;
  font-weight: bolder;
}
.markdown h4 {
  text-align: justify;
  font-size: 12px;
}
.markdown li {
  text-align: left;
  font-size: 12px;
}
strong {
  text-align: center;
}
table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
tr {
  border-top: 0px solid #c6cbd1;
  background: #fff;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
}
.table td,
.table th {
  text-align: justify;
  font-size: 12px;
  padding: 0.75rem;
  font-weight: 500;
  vertical-align: top;
  border-top: 0px solid #cccccc69;
}
.table thead th {
  font-weight: bolder;
  font-size: 14px;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
th,
td {
  padding: 6px 13px;
  border: 0px solid #dfe2e5;
}

table tr:nth-child(2n) {
  background: #f6f8fa;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef8c;
  opacity: 1;
  cursor: not-allowed;
}
.footer {
  margin-top: 1rem;
  padding: 1rem;
  border-top: 1px #ccc solid;
  position: static;
  bottom: 0;
  left: 0;
  width: 100%;
}
.footer p {
  font-size: 12px;
  cursor: pointer;
} /*-----unathorized!----*/
.message {
  max-width: 700px;
  margin: 5rem auto 0 auto;
}
.gandalf {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  top: 1rem;
  animation: floating 1s infinite alternate ease-in-out;
}
.gandalf div {
  position: absolute;
}
.gandalf::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
  background-color: #1a2130;
  border-radius: 50%;
}
.gandalf .fireball {
  bottom: -10px;
  left: 50px;
  width: 300px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(#efac41, #de8531, #6c1305, black);
  border: 5px solid #000;
}
.gandalf .skirt {
  bottom: 50px;
  left: 100px;
  border-bottom: 230px solid #ededed;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  filter: drop-shadow(0 0 6px #d4d4d4);
}
.gandalf .skirt::before {
  content: "";
  position: absolute;
  background-color: #ededed;
  width: 100px;
  height: 21px;
  top: 230px;
  left: 0px;
  border-bottom-right-radius: 180%;
  border-bottom-left-radius: 100%;
}
.gandalf .skirt::after {
  content: "";
  position: absolute;
  background-color: #ededed;
  width: 100px;
  height: 28px;
  top: 230px;
  left: -100px;
  border-bottom-right-radius: 80%;
  border-bottom-left-radius: 180%;
}
.gandalf .sleeves::before,
.gandalf .sleeves::after {
  content: "";
  position: absolute;
  border-bottom: 70px solid #ededed;
  filter: drop-shadow(0 0 6px #d4d4d4);
}
.gandalf .sleeves::before {
  top: 130px;
  left: 191px;
  border-left: 100px solid transparent;
  border-right: 40px solid transparent;
  transform: rotate(-34deg);
}
.gandalf .sleeves::after {
  top: 127px;
  left: 70px;
  border-left: 40px solid transparent;
  border-right: 100px solid transparent;
  transform: rotate(41deg);
}
.gandalf .shoulders {
  background-color: #ededed;
  border-radius: 50%;
  width: 100px;
  height: 130px;
  left: 150px;
  top: 120px;
}
.gandalf .shoulders .hand {
  width: 33px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffd8ad;
  top: -6px;
}
.gandalf .shoulders .left {
  left: -70px;
  transform: rotate(-20deg);
}
.gandalf .shoulders .left::after {
  content: "";
  position: absolute;
  background-color: #e6e6e6;
  width: 126px;
  height: 8px;
  border-radius: 4px;
  transform: rotate(-105deg);
  transform-origin: bottom;
  top: -48px;
  left: -56px;
}
.gandalf .shoulders .right {
  right: -70px;
  transform: rotate(20deg);
}
.gandalf .shoulders .right::after {
  content: "";
  position: absolute;
  background-color: #bf5507;
  width: 250px;
  height: 5px;
  border-radius: 2.5px;
  transform: rotate(-78deg);
  transform-origin: left;
  bottom: -100px;
  left: 0;
}
.gandalf .head {
  width: 80px;
  height: 90px;
  top: 80px;
  left: 160px;
  background-color: #ffd8ad;
  border-radius: 50%;
}
.gandalf .head::before,
.gandalf .head::after {
  content: "";
  position: absolute;
  background-color: #000;
}
.gandalf .head::before {
  width: 13px;
  height: 5px;
  border-radius: 3px;
  top: 42px;
  left: 22px;
  transform: rotate(19deg);
}
.gandalf .head::after {
  width: 13px;
  height: 5px;
  border-radius: 3px;
  top: 42px;
  right: 22px;
  transform: rotate(-19deg);
}
.gandalf .head .hair {
  width: 70px;
  height: 30px;
  background-color: #c2beb5;
  border-radius: 50%;
  top: 0px;
  left: 5px;
}
.gandalf .head .hair::before,
.gandalf .head .hair::after {
  content: "";
  position: absolute;
  background-color: #c2beb5;
  filter: drop-shadow(2px 5px 0 #aba599);
}
.gandalf .head .hair::before {
  top: 13px;
  left: -16px;
  width: 25px;
  height: 100px;
  border-top-left-radius: 34px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 20px;
  transform: rotate(8deg);
}
.gandalf .head .hair::after {
  top: 13px;
  right: -16px;
  width: 25px;
  height: 100px;
  border-top-left-radius: 15px;
  border-top-right-radius: 34px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 100px;
  transform: rotate(-10deg);
}
.gandalf .head .beard {
  top: 64px;
  left: 5px;
  border-top: 80px solid #c2beb5;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-radius: 30px;
  filter: drop-shadow(2px 5px 0 #aba599);
}
.gandalf .head .beard::before {
  content: "";
  position: absolute;
  background-color: pink;
  width: 20px;
  height: 5px;
  border-radius: 40%;
  top: -70px;
  left: -9px;
}
.message p {
  font-size: 20px;
  margin-top: 10px;
}
.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

/* .btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
} */

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
.cardSuccessHeader {
  background: #4980be;
  color: #ffff;
  margin-top: 0px;
}
.cardSuccessHeader .card-body {
  color: #ffff;
}
.cardSuccessHeader .svg-inline--fa {
  color: #fff;
  margin: 0px;
  font-size: 25px;
  text-align: center;
}
.cardSuccessHeader p {
  text-align: center;
  font-size: 18px;
}
.cardSuccessBody p {
  text-align: center;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.cardSuccessBody .card-body {
  color: #333;
  font-size: 500;
}
.cardSuccessBody {
  margin-top: 10px;
}

.billingCard .card-body h3 {
  text-align: center;
  color: #6b6a6a;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 25px;
}
.billingCard .card-body p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-top: 20px;
}
.billingCard .card-body .btn {
  float: center;
}
.modal-header .modal-title h5 {
  text-align: center;
}
.modal-header {
  display: block;
  text-align: center;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.btnStayIn {
  background-color: #4980be;
  color: #fff;
}
.btnStayIn:hover {
  background-color: #4980be;
  color: #fff;
}
.pricingSub,
.pricingSub:hover {
  border: 1px solid #4980be;
  border-radius: 10px;
  font-size: 12px;
  padding: 10px;
  font-weight: 300;
  color: #4980be;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -7rem -1rem -1rem auto;
}
@media screen and (max-width: 760px) {
  .mainNav .navbar {
    display: none;
  }
  .mainNav .mr-auto,
  .mx-auto {
    display: none;
  }
  .mainNav .navbar-nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    list-style: none;
    float: left;
  }
}
@media screen and (max-width: 760px) {
  .pro-sidebar {
    display: none;
  }
}
@media screen and (min-width: 700px) {
  .mobileNav .navbar {
    display: none;
  }
}
.siteCard .card {
  margin-top: 0px;
}
.siteCard .card .list-group-item {
  position: relative;
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 18px;
  background-color: #fff;
  border: 10px solid #cccccc38;
}

.card-title {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0.75rem;
}
.siteCardbody.card-body img {
  width: 100%;
}

.modal-sm .modal-title {
  font-size: 16px;
  padding: 15px;
}
.modal-sm {
  padding: 15px;
}

.modal-sm .btn-outline-danger {
  font-size: 12px;
  border-radius: 10px;
}
.modal-sm .btn-outline-primary {
  font-size: 12px;
  border-radius: 10px;
}
.modal-sm .btn-outline-secondary {
  font-size: 12px;
  border-radius: 10px;
}
.modal-sm .modal-header .close {
  padding: 1rem 1rem;
  margin: -6rem -1rem -1rem auto;
}

.modal-90w {
  margin-top: 18%;
  border-radius: 80px;
}
.modal-90w .modal-content {
  border: 1px solid #fff;
  border-radius: 20px;
}
.modal-90w .close {
  display: none;
}
.modal .btn-outline-primary {
  font-size: 12px;
  border-radius: 10px;
}
.modal .btn-outline-secondary {
  font-size: 12px;
  border-radius: 10px;
}
/* .modal-backdrop.show {
  opacity: .1;
} */
.modal .btn-primary {
  font-size: 12px;
  border-radius: 10px;
}
.table .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  clear: both;
  font-size: 12px;
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  clear: both;
  font-size: 12px;
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

.siteCard {
  background-color: #cccccc17;
  border: 0px black solid;
  text-align: justify;
}
.siteCard .p-2 {
  font-size: 12px;
  font-weight: 600;
}
.p-2 .svg-inline--fa {
  font-size: 30px;
  margin-top: -8px;
}
.p-2 .btn-outline-primary {
  margin-top: -15px;
  font-size: 12px;
}
/* @media only screen and (max-width: 600px) {
  .card {
   margin-right: 45px;
  }
} */

.statusCheck {
  font-size: 10px;
  display: inline;
}
.statusCheck .btn {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;

  padding: 0.375rem 0.75rem;

  line-height: 1.5;
  border-radius: 12px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.p-2 .btn-primary {
  font-size: 12px;
  margin-top: -12px;
}

@media only screen and (max-width: 600px) {
  .mobileupdate .btn-primary {
    font-size: 12px;
    margin-top: 10px;
    width: 100%;
  }
}

/* switch.css */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.planSwitch {
  margin-top: 20px;
}
/* switch.css ends */
.site-icon-wrapper {
  background-color: #cccccc8a;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 2px;
  font-size: 14px;
  width: 55px;
  min-width: 55px;
  height: 52px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
}
.site-icon-wrapper svg {
  margin-top: 5px;
}
.background {
  background-color: red;
}
.circleInfo {
  float: right;
  margin-top: 22%;
  margin-right: 0;
  width: 10px;
  height: 10px;
  background: green;
  border-radius: 50%;
}
.icon-container {
  width: 50px;
  height: 50px;
  position: relative;
}

.icon-container img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.icon-container span {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.status-circleOnline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}
.status-circleOffline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: grey;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 100%;
  font-weight: 100;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.copyText {
  color: #333;
  font-weight: bolder;
  padding: 6px;
  font-size: 10px;
  border-radius: 10px;
}
.badge-secondary {
  color: #fff;
  background-color: #85858a;
}
.badge-primary {
  color: #fff;
  background-color: #77c5f5;
}
.badge-info {
  color: #fff;
  background-color: #567eb9;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .hidemobile {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .hideDesktop {
    display: none;
  }
}

/* ---------------------------------------------------
    Account STYLE Ends
----------------------------------------------------- */

/* susbcription nav tabs---*/
.SubscriptionTabs .flex-column {
  flex-direction: row !important;
}
.SubscriptionTabs .flex-container {
  padding: 0;
  margin-right: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubscriptionTabs .flex-item {
  padding: 5px;
  width: 155px;
  height: 20px;
  margin: 10px;
  line-height: 20px;

  font-weight: bold;
  font-size: 2em;
  text-align: center;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4980be;
  border: 1px #4980be solid;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
  width: 120%;
}
.nav-pills .nav-link {
  color: #333;
  width: 120%;
  font-weight: 500;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
}
.couponDes {
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 20px;
}

.cancel-img {
  border-radius: 0px !important;
}

.coupon-main {
  max-width: 50%;
  margin-top: 50px;
}

@media only screen and (max-width: 800px) {
  .coupon-main {
    max-width: 90% !important;
    margin-top: 50px !important;
  }
  .card {
    margin-right: 0px !important;
  }
  .content {
    margin-left: 15px;
  }

  .subscriptionsContainer {
    max-width: 95%;
  }
}

.coupon_code:hover {
  cursor: pointer;
  color: #4980be;
}


.spinner .svg-inline--fa {
  color: #4980be;
  font-size: 75px;
  text-align: center;
  margin-top: 50px;
}