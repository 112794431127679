.custom-check {
  display: flex;
  gap: 10px;
}

.custom-check input {
  width: 20px;
  height: 20px;
}

.custom-check label {
  font-size: 20px;
  margin: 0;
}

.custom-check:hover {
  cursor: pointer;
}
